import { Database, DataSnapshot, onValue, push, ref, remove, set, Unsubscribe } from "firebase/database";
import { mapObjIndexed, ifElse, is, identity, always, pipe, defaultTo, evolve, split, equals, mergeRight } from "ramda";
import { Elm } from "./Main";

export type Init = {
  family: string;
  today: string;
  date: string;
  db: Database;
  app: Elm.Main.App
}

const toVal = (snapshot: DataSnapshot) => snapshot.val();

const listener = (db: Database, dataPath: string, callback: (val: DataSnapshot) => any) => {
  let unsubscribe: Unsubscribe | null = null;

  const subscribe = () => {
    unsubscribe && unsubscribe();
    unsubscribe = onValue(ref(db, dataPath), callback);
  };

  subscribe();
  setInterval(() => subscribe, 1000 * 60 * 60)    
}

const initialList = mapObjIndexed(ifElse(is(Object), identity, always(null)))

export const init = ({ app, family, date, today, db }: Init) => {

  listener(db, `/families/${family}/config`, pipe(
    toVal,
    defaultTo({ kids: [], order: '' }),
    evolve({ order: split(',') }),
    app.ports.updateConfig.send.bind(app.ports.updateConfig)
  ));
  
  listener(db, `/families/${family}/lists/library`, pipe(
    toVal,
    defaultTo({}),
    mapObjIndexed(evolve({ items: ifElse(equals(0), always({}), identity) }) as any),
    app.ports.updateLibrary.send.bind(app.ports.updateLibrary)
  ));

  listener(db, `/families/${family}/lists/templates`, pipe(
    toVal,
    initialList,
    mergeRight({ list: 'templates' }),
    app.ports.updateTasks.send.bind(app.ports.updateTasks)
  ));

  listener(db, `/families/${family}/lists/${date}`, pipe(
    toVal,
    initialList,
    mergeRight({ list: date }),
    app.ports.updateTasks.send.bind(app.ports.updateTasks)
  ));

  app.ports.addGroup.subscribe(({ target, title }) => {
    switch (target) {
      case 'library':
        set(push(ref(db, `/families/${family}/lists/library`)), { title, items: 0 })
    }
  });

  app.ports.addTask.subscribe(({ path, title }) => {
    switch (path) {
      case 'library':
        return set(push(ref(db, `/families/${family}/lists/library`)), { title })
  
      default:
        return set(push(ref(db, `/families/${family}/lists/${date}/${path}`)), { title })
    }
  });

  app.ports.deleteTask.subscribe(({ path }) => {
    const task = path.startsWith('/library')
      ? `/families/${family}/lists/${path}`
      : `/families/${family}/lists/${date}/${path}`
    remove(ref(db, task))
  });

  app.ports.checkTask.subscribe(({ path, checked }) => {
    set(ref(db, `/families/${family}/lists/${date}/${path}/completed`), (
      checked ? Math.round(Date.now() / 1000) : false
    ))
  });
}