import { curry } from 'ramda';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, signOut, GoogleAuthProvider } from "firebase/auth";
import { getDatabase, ref, set, onValue, child, push, update, remove, Unsubscribe, DataSnapshot } from "firebase/database";

import registerServiceWorker from "./register-service-worker";

import { Elm } from './Main';
import { init } from './bootstrap';

const firebaseApp = initializeApp((window as any).APP_CONFIG = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID
});

const provider = new GoogleAuthProvider();
const auth = getAuth();
const db = getDatabase(firebaseApp);

const pad = curry((length: number, val: string | number) => val.toString().padStart(length, '0'));
const toDateString = (d: Date) => `${d.getFullYear()}-${pad(2, d.getMonth() + 1)}-${pad(2, d.getDate())}`;
const today = toDateString(new Date());
let [family, date] = location.pathname.substring(1).split('/');
date = date || today;

Object.assign(window, { toDateString });

const app = Elm.Main.init({
  node: document.getElementsByTagName('main')[0],
  flags: {}
});

if (!family && localStorage.family) {
  family = localStorage.family;
  history.pushState({}, document.title, '/abele');
}

if (family) {
  Object.assign(localStorage, { family })
  init({ app, db, family, today, date });
}

const relative = (dateVal: string, dir: 'prev' | 'next') => {
  const d = new Date(Date.parse(dateVal + 'T01:01:01'));
  d.setDate(d.getDate() + (dir === 'prev' ? -1 : 1));
  return toDateString(d);
}

app.ports.switchDay.subscribe(day => {
  if (day === 'today') {
    return (window.location.pathname = `/${family}`);
  }
  const newDate = relative(date, day);

  return newDate === today
    ? (window.location.pathname = `/${family}`)
    : (window.location.pathname = `/${family}/${newDate}`);
});

/**
 * Every 10 minutes, check and see if it's still today
 */
setInterval(() => {
  if (toDateString(new Date()) !== today) {
    history.go(0);
  }
}, 1000 * 60 * 10)

document.body.addEventListener('dragover', _ => {
  // this is needed in order to make dragging work
  return false;
});


// app.ports.signIn.subscribe(() => {
//   signInWithPopup(auth, provider)
//     .then(({ user: { getIdToken, email, uid} }) => {
//       getIdToken().then(token => app.ports.signInInfo.send({ token, email, uid }));
//     })
//     .catch(({ code, message }) => {
//       app.ports.signInError.send({ code, message, credential: null });
//     });
// });

// app.ports.signOut.subscribe(() => {
//   console.log("LogOut called");
//   signOut(auth);
// });

// //  Observer on user info
// onAuthStateChanged(auth, user => {
//   console.log("onAuthStateChanged called", user);
//   if (user) {
//     user
//       .getIdToken()
//       .then(idToken => {
//         app.ports.signInInfo.send({
//           token: idToken,
//           email: user.email,
//           uid: user.uid
//         });
//       })
//       .catch(error => {
//         console.log("Error when retrieving cached user");
//         console.log(error);
//       });

//     const q = query(collection(db, `users/${user.uid}/messages`));

//     onSnapshot(q, querySnapshot => {
//       console.log("Received new snapshot", querySnapshot);
//       const messages: any[] = [];

//       querySnapshot.forEach(doc => {
//         if (doc.data().content) {
//           messages.push(doc.data().content);
//         }
//       });

//       app.ports.receiveMessages.send({ messages });
//     });
//   }
// });

// app.ports.saveMessage.subscribe(({ uid, content }) => {
//   console.log(`saving message to database : ${content}`);

//   addDoc(collection(db, `users/${uid}/messages`), { content }).catch(error => {
//     app.ports.signInError.send({
//       code: error.code,
//       message: error.message,
//       credential: null
//     });
//   });
// });

registerServiceWorker();
